import React from 'react';
import './App.css';
import Main from './Components/Main/Main';
 
export default class App extends React.Component{
  render(){
    return (
      <div className="App">           
        <Main />
      </div>
    );
  }
}

